body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-align content horizontally */
}

header {
    background-color: #f4f4f4;
    padding: 2rem;
    text-align: center;
    border-bottom: 1px solid #ddd;
    width: 100%;
    display: flex;
    justify-content: center; /* Center-align header content horizontally */
    align-items: center;
    flex-direction: column;
}

.main-content {
    display: flex;
    width: 100%;
    max-width: 1200px; /* Optional: Limit max width for better centering */
    line-height: 1.5;
}

.navbar {
    width: 33.33%;
    padding: 1rem;
    box-sizing: border-box;
    background-color: white; /* Plain background */
    border-right: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding-right: 2rem;
    position: sticky;
    top: 8rem; /* Adjust based on the header height */
    max-height: calc(100vh - 8rem); /* Ensure it doesn't exceed the viewport height minus header */
    overflow-y: auto; /* Add scrolling if needed */
    margin-top: 2rem; /* Adjust to align with h2 in #home */
}

.navbar nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.navbar nav ul li {
    margin-bottom: 1rem;
    text-align: right;
}

.navbar nav ul li a {
    color: black;
    text-decoration: none;
}

.navbar nav ul li a:hover {
    text-decoration: underline;
}

section {
    padding: 2rem 0;
    /* border-bottom: 1px solid #ddd; */
}

h2 {
    border-bottom: 2px solid #333;
    padding-bottom: 0.5rem;
    margin-top: 0; /* Ensure no extra margin above h2 */
}

.party {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem; /* Add gap between text and image */
    border-bottom: 1px solid #ddd;
    justify-content: space-between; /* Ensure space between text and image */
}

#models .party {
    padding-bottom: 2rem;
    padding-top: 1rem;
}

.report {
    display: flex;
    flex-direction: column;
}

.content {
    padding: 2rem 1rem;
    width: 75%;
}

.document {
    padding-top: 1rem;
}

.party .report {
    flex: 1;
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
}

.party .picture {
    max-width: 200px; /* Adjust the max width as needed */
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Align images to the right */
}

.party .picture img {
    width: 100%;
    height: auto;
    display: block;
    cursor: pointer; /* Change cursor to indicate clickability */
    transition: transform 0.3s ease; /* Smooth transition */
}

.enlarged {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.5); /* Enlarge the image */
    z-index: 1000; /* Ensure the image is on top */
}

.enlarged-controls {
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1001;
    display: flex;
    gap: 1rem;
}

.enlarged-controls button {
    background-color: #333;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
    outline: none;
}

.enlarged-controls button:hover {
    background-color: #555;
}

.party h3 {
    margin-bottom: 0.5rem; /* Adjust the margin as needed */
    align-self: flex-start;
}

.party p {
    margin-top: 0.5rem; /* Adjust the margin as needed */
    align-self: flex-start;
}

/* Add styles for lists */
ul, ol {
    /* border: 2px orange solid; */
    margin: 0 0 0 20px; /* Adjust margins as needed */
    padding: 0;
    list-style-position: inside; /* Ensure bullets/numbers are inside the content area */
}

ul li, ol li {
    /* margin: 0.25rem 0; Reduce the gap between list items */
    /* font-size: 0.9rem; Make the text smaller */
    line-height: 1.5rem;
    /* border: 2px orange solid; */
    padding: 0;
}

ol li ul {
    /* border: 2px orange solid; */
    line-height: 1.5rem;
}

/* Responsive design */
@media screen and (max-width: 768px) {
    .main-content {
        flex-direction: column;
    }

    .navbar {
        width: 100%;
        height: auto;
        position: sticky; /* Make navbar sticky */
        top: 0; /* Stick to the top of the page */
        border-right: none;
        border-bottom: 1px solid #ddd;
        align-items: center; /* Center align items */
        margin-top: 0;
        padding-right: 0;
        max-height: none; /* Remove max height restriction */
        overflow-y: visible; /* Remove overflow for small screens */
    }

    .navbar nav ul li {
        text-align: center; /* Center align text */
        margin-right: 0;
    }

    .content {
        width: 100%;
        padding: 1rem;
        padding-left: 1rem;
    }

    section {
        padding: 1rem 0;
    }

    .party {
        flex-direction: column; /* Stack text and image vertically on small screens */
    }

    .party .picture {
        max-width: 100%; /* Ensure image fits the container width */
        align-items: center; /* Center align images */
    }
}

.menu-toggle {
    display: none; /* Hidden by default */
    position: fixed;
    top: 10px;
    left: 10px;
    background-color: #333;
    color: white;
    padding: 10px;
    cursor: pointer;
    font-size: 1.5rem;
    z-index: 1002; /* Ensure it stays above other content */
}

@media screen and (max-width: 768px) {
    .menu-toggle {
        display: block; /* Show the menu toggle button on small screens */
    }

    .navbar {
        display: none; /* Hide navbar by default on small screens */
    }

    .navbar.active {
        display: flex; /* Show navbar when active */
    }
}
